import axios from 'axios';
const { ENDPOINT, HEADERS, TYPE_POINT } = require('./constant');

export function handleApi(event, dataInput) {
  event.preventDefault();
    axios.post(ENDPOINT.STRIPE_URL, dataInput)
      .then(res => {
        // window.location.href =res.data;
        localStorage.setItem('clientSecretTest',res.data.clientSecret)
        return res.data;
      })
      .catch(error => {
        console.log(error.response)
    });
}

export const  pointsTransaction = async(event, dataInput) => {
  try {
    event.preventDefault();
    return await axios.post(ENDPOINT.CREATE_PURCHASE, dataInput)
  } catch (error) {
    console.log(error);
  }
}

export const sendOrderNotification = async(event, mailData) => {
  try {
    event.preventDefault();
    await axios.post(ENDPOINT.SEND_NOTICE_MAIL, mailData);
  } catch (error) {
    console.log(error);
  }
}

export const updateBalancePoint = async(event, dataInput) => {
  try {
    event.preventDefault();
     axios.put(ENDPOINT.UPDATE_BALANCE_POINT, dataInput)
     .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log(error.response)
  });
  } catch (error) {
    console.log(error);
  }
}

export const getPublicKey = async(event,dataInput) => {
  try {
    event.preventDefault();
     axios.get(`${ENDPOINT.GET_PUBLIC_KEY}${dataInput}`)
     .then(res => {
      localStorage.setItem('publicKey',res.data.stripePublicKey)
      return res.data;
    })
    .catch(error => {
      console.log(error.response)
  });
  } catch (error) {
    console.log(error);
  }
}

export const  createProductPrice = async(data) => {
  try {
     axios.post(ENDPOINT.CREATE_PRODUCT_PRICE, data)
     .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log(error.response)
  });
  } catch (error) {
    console.log(error);
  }
}

export const getProductPrice = (dataInput) => {
  try {
  axios.get(`${ENDPOINT.GET_PRODUCT_PRICE}${dataInput}`)
    .then(res =>{
      return res.data
    })
    .catch(error => {
      console.log(error.response)
  });
  } catch (error) {
    console.log(error);
  }
}

export const updateProductPrice = (dataInput) => {
  try {
     axios.put(ENDPOINT.UPDATE_PRODUCT_PRICE, dataInput)
     .then(res => {
      return res.data;
    })
    .catch(error => {
      console.log(error)
  });
  } catch (error) {
    console.log(error);
  }
}

export const deleteProductPrice = (productPriceId) => {
  try {
  axios.delete(`${ENDPOINT.DELETE_PRODUCT_PRICE}${productPriceId}`)
    .then(res =>{
      return res.data
    })
    .catch(error => {
      console.log(error.response)
  });
  } catch (error) {
    console.log(error);
  }
}


export const soliCarbonOffset = async(dataInput) => {
  try {
    axios.post(ENDPOINT.CREATE_SOLI_CARBON_OFFSET, dataInput)
    .then(res => {
     return res.data;
   })
   .catch(error => {
     console.log(error.response)
 });
 } catch (error) {
   console.log(error);
 }
}

export const  allocateCarbonOffset = async(dataInput) => {
  try {
   return await axios.post(ENDPOINT.ALLOCATE_CARBON_OFFSET, dataInput,{headers:HEADERS})
  } catch (error) {
    console.log(error);
  }
}

export const  getRewarData = async(userId, pointId) => {
  try {
   return await axios.get(`${ENDPOINT.GET_REWARD_DATA}${userId}/${pointId}`)
  } catch (error) {
    console.log(error);
  }
}

export const getListCarbonOffsets =  async(userId,typePoint) => {
  try {
    return await axios.get(`${ENDPOINT.CREATE_SOLI_CARBON_OFFSET}/${userId}/${typePoint}`)
  } catch (error) {
    console.log(error);
  }
}

export const getBalanceClimateReward =  async(customerUserId) => {
  try {
    const companyId = 'a67b662d-c543-446e-9d3d-e39a147c9800'
    const campaignId = 'b8579ed1-5e8a-49a8-843b-063b29886acd'
    return await axios.get(`${ENDPOINT.GET_BALANCE_CLIMATE_REWARD}?companyId=${companyId}&campaignId=${campaignId}&customerUserId=q${customerUserId}`,{headers:HEADERS}) 
  } catch (error) {
    console.log(error);
  }
}

export const redeemRewardPoints =  async(customerTransactionId,customerUserId, redeemPoints) => {
  try {
    const result = {
      companyId: "a67b662d-c543-446e-9d3d-e39a147c9800",
      campaignId: "b8579ed1-5e8a-49a8-843b-063b29886acd",
      customerUserId: `q${customerUserId}`,
      redeemPoints: redeemPoints,
      customerTransactionId: customerTransactionId,
      customerOrgId: "cOrgID-001",
      optionId: "9b540064-ebf8-4f39-822d-5660a807886b"
      }
    return await axios.post(ENDPOINT.REDEEM_REWARD_POINTS,result,{headers:HEADERS}) 
  } catch (error) {
    console.log(error);
  }
}

export const createRewardPoints = async (customerTransactionId, customerUserId, rewardPoints) => {
  try {
    let dataInput = {
      companyId: "a67b662d-c543-446e-9d3d-e39a147c9800",
      campaignId: "b8579ed1-5e8a-49a8-843b-063b29886acd",
      customerUserId: `q${customerUserId}`,
      rewardPoints: rewardPoints,
      customerTransactionId: customerTransactionId,
      customerOrgId: "a67b662d-c543-446e-9d3d-e39a147c9800",
      customerOrgName: "Enterprise Corp",
      customerAccountId: "a67b662d-c543-446e-9d3d-e39a147c9800",
      userPhone: "",
      userEmail: "",
      userFirstName: "",
      userLastName: ""
    }
    return await axios.post(ENDPOINT.CREATE_REWARD_CLAIMATE, dataInput, { headers: HEADERS })
  } catch (error) {
    console.log(error);
  }
}

export const saveRewardClimateCoins = async (customerTransactionId, customerUserId, product, quatity) => {
  try {
    const climateCoins = []
    const dataTransactionId = customerTransactionId.filter((id) => id !== customerTransactionId[1].id)
    const dataProduct = product.filter((item) => item.id !== 'a7405ba7-bfea-41b8-a184-53281107a815')
    dataProduct.forEach((product) => {
      let dataInput = {
        customerTransactionId: product.id == 'a8250869-1f38-4baa-8669-319f1622cd02' ? dataTransactionId[0].id : dataTransactionId[1].id,
        companyId: 'd8b97f44-e13a-4109-b498-2a73bdac7b0e',
        campaignId: "b8579ed1-5e8a-49a8-843b-063b29886acd",
        customerOrgId: "a67b662d-c543-446e-9d3d-e39a147c9800",
        customerOrgName: "Enterprise Corp",
        customerAccountId: "a67b662d-c543-446e-9d3d-e39a147c9800",
        customerUserId: customerUserId,
        productId: product.id,
        rewardPoints: Number(quatity[product.id] * 25),
        typePoint: TYPE_POINT.CLIMATE_COIN,
      }
      climateCoins.push(dataInput)
    });
    return await axios.post(ENDPOINT.SAVE_CLIMATE_COINS, climateCoins)
  } catch (error) {

  }
}

export const getAllDataOfUser = async(userId, companyId) => {
  try {
   return await axios.get(`${ENDPOINT.GET_ALL_DATA_USER}${userId}/${companyId}`)
  } catch (error) {
    console.log(error);
  }
}

export const claimCustomerReward = async (dataInput) => {
  try {
    return await axios.post(ENDPOINT.REWARD_CUSTOMER_CLIME_TOKEN, dataInput)
  } catch (error) {
    console.log(error);
  }
}

export const getNftOfAddress = async (address) => {
  try {
    return await axios.get(`https://api.tatum.io/v3/nft/address/balance/CELO/${address}`,{
      headers: {
        'x-api-key': 't-6641854dbd77e8001c7772fb-5fe11df27ef542c8bf34c088'
      }
    })
  } catch (error) {
  }
}

export const getNftMetadata = async (url) => {
  try {
    return await axios.get(url)
  } catch (error) {
  }
}
